<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <b-col
        class="content-header d-flex align-items-start justify-content-between"
        cols="12"
        lg="9"
        md="10"
        sm="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1"></div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </b-col>
    </b-row>
    <div
      v-if="
        isLoadingWasteCollectionPoint ||
        isCreatingWasteCollectionPoint ||
        isUpdatingWasteCollectionPoint
      "
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du point de collecte...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="
        content-scrollable-sticky
        m-0
        justify-content-center
        align-items-between
      "
      style="height: calc(100% - 110px)"
    >
      <b-col
        cols="12"
        lg="9"
        md="10"
        sm="12"
        class="content-scrollable-sticky-main h-100"
      >
        <validation-observer
          ref="formCreateWasteCollectionPoint"
          autocomplete="off"
        >
          <b-form autocomplete="off" class="h-100" style="overflow-x: hidden">
            <b-row id="general" class="my-0">
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Nom * " label-for="label">
                      <validation-provider
                        #default="{ errors }"
                        name="label"
                        rules="required"
                      >
                        <b-form-input
                          id="label"
                          v-model="wasteCollectionPointForm.label"
                          :state="errors.length &gt; 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Type *"
                      label-for="wasteCollectionPointTypeId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="wasteCollectionPointTypeId"
                        rules="required"
                      >
                        <v-select
                          id="wasteCollectionPointTypeId"
                          :state="errors.length &gt; 0 ? false : null"
                          :reduce="(el) => el.id"
                          v-model="
                            wasteCollectionPointForm.wasteCollectionPointTypeId
                          "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="wasteCollectionPointTypesList"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12" class="pb-0">
                    <b-form-group :label-for="'labelAddress'">
                      <template slot="label">
                        <div class="d-flex w-100 justify-content-between">
                          <label :for="'labelAddress'">Adresse *</label>
                        </div>
                      </template>
                      <validation-provider #default="{ errors }" name="Address">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="mb-0 not-autocomplete"
                          :id="'address'"
                          v-model="wasteCollectionPointForm.address"
                          :ieCloseFix="false"
                          :data="autocompleteAddressData"
                          :serializer="(item) => item.properties.label"
                          :showAllResults="true"
                          placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                          @input="autocompleteAddressSource"
                          @hit="autocompleteAddressSelected"
                          autocomplete="nope"
                          aria-autocomplete="nope"
                        >
                          <template
                            slot="suggestion"
                            slot-scope="{ data, htmlText }"
                          >
                            <div class="item">
                              <small>{{ data.properties.name }}</small>
                              <span>{{
                                data.properties.postcode +
                                " " +
                                data.properties.city
                              }}</span>
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="AddressComplement"
                    >
                      <b-form-input
                        class="mb-0"
                        :id="'addressComplement'"
                        v-model="wasteCollectionPointForm.addressComplement"
                        :state="errors.length &gt; 0 ? false : null"
                        placeholder="Bâtiment, Appartement.."
                      ></b-form-input>
                    </validation-provider>
                  </b-col>
                  <b-col cols="3">
                    <validation-provider #default="{ errors }" name="Zipcode">
                      <b-form-input
                        class="mb-0"
                        :id="'zipcode'"
                        v-model="wasteCollectionPointForm.zipCode"
                        :state="errors.length &gt; 0 ? false : null"
                        placeholder="Code postal"
                        ><small class="text-danger">{{
                          errors[0]
                        }}</small></b-form-input
                      >
                    </validation-provider>
                  </b-col>
                  <b-col cols="9">
                    <validation-provider #default="{ errors }" name="City">
                      <b-form-input
                        class="mb-0"
                        :id="'city'"
                        v-model="wasteCollectionPointForm.city"
                        :state="errors.length &gt; 0 ? false : null"
                        placeholder="Ville"
                        ><small class="text-danger">{{
                          errors[0]
                        }}</small></b-form-input
                      >
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider #default="{ errors }" name="Country">
                      <b-form-input
                        class="mb-0"
                        :id="'country'"
                        v-model="wasteCollectionPointForm.country"
                        :state="errors.length &gt; 0 ? false : null"
                        placeholder="Pays"
                        ><small class="text-danger">{{
                          errors[0]
                        }}</small></b-form-input
                      >
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button variant="outline-primary" @click="cancel()">
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="wasteCollectionPointForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="
                    archiveWasteCollectionPointLocal(
                      wasteCollectionPointForm.id,
                      wasteCollectionPointForm.name
                    )
                  "
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>

              <b-button variant="primary" @click="create">
                <feather-icon
                  icon="SaveIcon"
                  v-if="wasteCollectionPointForm.id"
                />
                <feather-icon icon="PlusIcon" v-else />
                {{ wasteCollectionPointForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { Trash2Icon, StarIcon, CheckCircleIcon } from "vue-feather-icons";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton, VBTooltip } from "bootstrap-vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scrolled: false,
      autocompleteAddressData: [],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine: {},
      wasteCollectionPointForm: {},
    };
  },
  async created() {
    this.popupWasteCollectionPointEvent(this.id);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }

      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupWasteCollectionPointEvent(id) {
      this.getWasteCollectionPointTypes({});
      if (id > 0) {
        this.getWasteCollectionPointById({ wasteCollectionPointId: id }).then(
          (res) => {
            this.wasteCollectionPointForm = res;
            this.wasteCollectionPointForm.wasteCollectionPointTypeId =
              res.wasteCollectionPointType.id;
            delete this.wasteCollectionPointForm.wasteCollectionPointType;
            this.dataOrigine = JSON.stringify(this.wasteCollectionPointForm);
          }
        );
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.wasteCollectionPointForm = {
        id: 0,
        label: null,
        address: null,
        addressComplement: null,
        zipCode: null,
        city: null,
        country: null,
        wasteCollectionPointTypeId: null,
      };
      this.dataOrigine = JSON.stringify(this.wasteCollectionPointForm);
    },
    ...mapActions([
      "createWasteCollectionPoint",
      "getWasteCollectionPointById",
      "updateWasteCollectionPoint",
      "archiveWasteCollectionPoints",
      "getWasteCollectionPointTypes",
    ]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateWasteCollectionPoint
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.wasteCollectionPointForm.id)
              this.updateWasteCollectionPoint({
                wasteCollectionPoint: this.wasteCollectionPointForm,
              });
            else
              this.createWasteCollectionPoint({
                wasteCollectionPoint: this.wasteCollectionPointForm,
              });

            this.$nextTick(() => {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close({ to: "/waste-collection-points" });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function () {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.wasteCollectionPointForm.address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.wasteCollectionPointForm.address = item.properties.name;
      this.wasteCollectionPointForm.city = item.properties.city;
      this.wasteCollectionPointForm.zipCode = item.properties.postcode;
      this.wasteCollectionPointForm.country = "France";
    },

    archiveWasteCollectionPointLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver le " +
              this.wasteCollectionPointForm.label.toLowerCase() +
              ' "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveWasteCollectionPoints({
              wasteCollectionPointIds: [id],
            });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.wasteCollectionPointForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingWasteCollectionPoint",
      "wasteCollectionPointTypesList",
      "isCreatingWasteCollectionPoint",
      "isUpdatingWasteCollectionPoint",
    ]),
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    Trash2Icon,
    StarIcon,
    CheckCircleIcon,
  },
  directives: {
    Ripple,
    mask,
    "b-tooltip": VBTooltip,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
