var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0 justify-content-center"},[_c('b-col',{staticClass:"content-header d-flex align-items-start justify-content-between",attrs:{"cols":"12","lg":"9","md":"10","sm":"12"}},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"}),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])],1),(
      _vm.isLoadingWasteCollectionPoint ||
      _vm.isCreatingWasteCollectionPoint ||
      _vm.isUpdatingWasteCollectionPoint
    )?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"\n      content-scrollable-sticky\n      m-0\n      justify-content-center\n      align-items-between\n    ",staticStyle:{"height":"calc(100% - 110px)"}},[_c('b-col',{staticClass:"content-scrollable-sticky-main h-100",attrs:{"cols":"12","lg":"9","md":"10","sm":"12"}},[_c('validation-observer',{ref:"formCreateWasteCollectionPoint",attrs:{"autocomplete":"off"}},[_c('b-form',{staticClass:"h-100",staticStyle:{"overflow-x":"hidden"},attrs:{"autocomplete":"off"}},[_c('b-row',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nom * ","label-for":"label"}},[_c('validation-provider',{attrs:{"name":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"label","state":errors.length > 0 ? false : null},model:{value:(_vm.wasteCollectionPointForm.label),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "label", $$v)},expression:"wasteCollectionPointForm.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Type *","label-for":"wasteCollectionPointTypeId"}},[_c('validation-provider',{attrs:{"name":"wasteCollectionPointTypeId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"wasteCollectionPointTypeId","state":errors.length > 0 ? false : null,"reduce":function (el) { return el.id; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.wasteCollectionPointTypesList},model:{value:(
                          _vm.wasteCollectionPointForm.wasteCollectionPointTypeId
                        ),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "wasteCollectionPointTypeId", $$v)},expression:"\n                          wasteCollectionPointForm.wasteCollectionPointTypeId\n                        "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":'labelAddress'}},[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('label',{attrs:{"for":'labelAddress'}},[_vm._v("Adresse *")])])]),_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{staticClass:"mb-0 not-autocomplete",attrs:{"type":"text","state":errors.length > 0 ? false : null,"id":'address',"ieCloseFix":false,"data":_vm.autocompleteAddressData,"serializer":function (item) { return item.properties.label; },"showAllResults":true,"placeholder":"Rechercher une adresse ou saisissez : Rue et n° de rue","autocomplete":"nope","aria-autocomplete":"nope"},on:{"input":_vm.autocompleteAddressSource,"hit":_vm.autocompleteAddressSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
                        var data = ref.data;
                        var htmlText = ref.htmlText;
return [_c('div',{staticClass:"item"},[_c('small',[_vm._v(_vm._s(data.properties.name))]),_c('span',[_vm._v(_vm._s(data.properties.postcode + " " + data.properties.city))])])]}}],null,true),model:{value:(_vm.wasteCollectionPointForm.address),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "address", $$v)},expression:"wasteCollectionPointForm.address"}})]}}])})],2)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"AddressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":'addressComplement',"state":errors.length > 0 ? false : null,"placeholder":"Bâtiment, Appartement.."},model:{value:(_vm.wasteCollectionPointForm.addressComplement),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "addressComplement", $$v)},expression:"wasteCollectionPointForm.addressComplement"}})]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":'zipcode',"state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(_vm.wasteCollectionPointForm.zipCode),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "zipCode", $$v)},expression:"wasteCollectionPointForm.zipCode"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":'city',"state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(_vm.wasteCollectionPointForm.city),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "city", $$v)},expression:"wasteCollectionPointForm.city"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":'country',"state":errors.length > 0 ? false : null,"placeholder":"Pays"},model:{value:(_vm.wasteCollectionPointForm.country),callback:function ($$v) {_vm.$set(_vm.wasteCollectionPointForm, "country", $$v)},expression:"wasteCollectionPointForm.country"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.wasteCollectionPointForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveWasteCollectionPointLocal(
                    _vm.wasteCollectionPointForm.id,
                    _vm.wasteCollectionPointForm.name
                  )}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.wasteCollectionPointForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.wasteCollectionPointForm.id ? "Modifier" : "Ajouter")+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_vm._v(" Chargement des détails du point de collecte... ")])])}]

export { render, staticRenderFns }